import DisputesService from '../services/disputes-service';

export const disputes = {
  namespaced: true,
  state: {
    project: null,
  },
  actions: {
    getProject({ commit }, id) {
      commit('setIsBusy', true, { root: true });
      commit('setProject', []);
      return DisputesService.getProject(id)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          commit('setProject', result.model);
          return Promise.resolve(result.model);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
    authorise({ commit }, payload) {
      commit('setIsBusy', true, { root: true });
      // commit('setBlockedEmails', []);
      return DisputesService.authorise(payload)
        .then((result) => {
          commit('setIsBusy', false, { root: true });
          return Promise.resolve(result.model);
        })
        .catch((error) => {
          commit('setIsBusy', false, { root: true });
          return Promise.reject(error);
        });
    },
  },
  mutations: {
    setProject(state, newState) {
      state.project = newState;
    },
  },
};
